import {useContext, useState} from 'react'
import Typography from "Clutch/Atoms/Typography";
import styles from './stickyHeader.module.scss'
import TextLink from "Clutch/Atoms/TextLink";
import styled from "styled-components";
import LayerColorFinder from "Clutch/Utilities/LayerColorFinder";
import Svg from "Clutch/Atoms/Svg";
import useProductResultPage from "../../hooks/useProductResultPage";
import useVehicleSidebar from "../../hooks/useVehicleSidebar";
import ClassNames from 'classnames'
import StickyHeaderSkeleton from './stickyHeaderSkeleton'
import Button from 'Clutch/Molecules/Button'
import { usePrpStore } from '/contexts/prpContext'
import { showAddToGarage } from '../../Utilities/garageUtilities';
import addCurrentVehicle from "../../Utilities/addCurrentVehicle";
import { useUserStore } from "Clutch/Stores/UserStore/UserStore";
import {AddUserVehicle} from 'Clutch/Contexts/VehicleContext/CoreVehicleRequests'
import {NotificationContext} from 'Clutch/Contexts/NotificationContext/NotificationContext'
import {createClickedSegmentEvent} from "Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents";
import {useVehicleStore} from "Clutch/Stores/VehicleStore";

const StickyHeaderContent = ({onlyChips = false, location, classNames}) => {

    const customerVehicles = useVehicleStore(x => x.context.customerVehicles)
    const UpdateCustomerVehicles = useVehicleStore(x => x.UpdateCustomerVehicles)


    const selectedFacets = useProductResultPage(x => x.selectedFacetOptions)
    const skeleton = useProductResultPage(x => x.skeleton)
    const isNoResultPage = useProductResultPage(x => x.isNoResultPage)
    const isFacetResultPage = useProductResultPage(x => x.isFacetResultPage)
    const {isVehicleSelected, vehicleName} = useVehicleSidebar(selectedFacets);
    const OpenSidebarDrawer = usePrpStore(x => x.OpenSidebarDrawer)
    const openAddOrSelectVehicleExperience = useVehicleStore(state => state.openAddOrSelectVehicleExperience)
    const selectedFacetData = useVehicleStore(x => x.context.selectedFacetData)
    const CloseSidebarDrawer = usePrpStore(x => x.CloseSidebarDrawer)

    const canAddVehicleToGarage = showAddToGarage(selectedFacets, customerVehicles)
    const getGuid = useUserStore((x) => x.context.getGuid);
    const isBot = useUserStore((x) => x.context.isBot);
    const { PushNotification } = useContext(NotificationContext)
    const [addingToGarage, setAddingToGarage] = useState(false)
    const brand = 'secondary'
    const backgroundColor = LayerColorFinder(3, false, brand, null)
    const baseColor = LayerColorFinder(0);
    const gradientColors = {
        "--b": baseColor,
        "--i": baseColor.slice(0, -2) + "0.0)",
    };

    // search response should not change from SearchQuery in the future, but it shouldn't count as a searchResponseFacet
    const filterFacetsCount = selectedFacets?.filter(currentFacet => currentFacet.searchResponseFacetOptions).length
    const facetsSelected = filterFacetsCount > 0;
    const selectedFacetText = facetsSelected ? `(${filterFacetsCount})` : "";

    if (skeleton) {
        return <StickyHeaderSkeleton/>
    }
    if (isNoResultPage) {
        return false
    }

    const addCurrentVehicleToGarage = async () => {

        addCurrentVehicle(selectedFacets, isBot, getGuid, selectedFacetData, UpdateCustomerVehicles, AddUserVehicle, setAddingToGarage,
            () => {
                PushNotification(`${vehicleName} added to your garage!`, "positive", 5000, null, {
                    intent: "postitive",
                    icon: "check"
                });
            },
            () => {
                PushNotification(`Something went wrong, please try that again`, 'negative')
            })
    }

    return (
        <div
            className={ClassNames(styles.stickyHeaderContainer, classNames, isFacetResultPage ? styles.forFrp : false)}>

            <Button brand={'primary'} size={'small'} className={styles.stickyHeaderButton}
                    segmentEvent={createClickedSegmentEvent('PrpStickyHeaderSortAndFilter')}
                    testingName={'filterAndSortStickyHeader'} onClick={() => OpenSidebarDrawer()}>
                <Svg icon="filter-sort" size={1.25} className={styles.filterIcon}></Svg>
                <Typography size={0.875} font={'bold'}>{`Sort & Filter`}</Typography>
            </Button>
            {!isVehicleSelected ?
                <Button brand={'secondary'} size={'small'} className={styles.stickyHeaderButton}
                        segmentEvent={createClickedSegmentEvent('SelectVehicleStickyHeader')}
                        testingName={'selectVehicleStickyHeader'} onClick={() => openAddOrSelectVehicleExperience({
                    redirectWhenVehicleSelected: true,
                    closeVehicleDrawerCallback: CloseSidebarDrawer
                })}>
                    <Typography size={0.875}
                                font={'bold'}>{isVehicleSelected ? vehicleName : 'Select Vehicle'}</Typography>
                </Button>
                : (canAddVehicleToGarage ?
                    <Button isWaiting={addingToGarage} brand={'secondary'} size={'small'}
                            className={styles.stickyHeaderButton}
                            segmentEvent={createClickedSegmentEvent('AddToGarageStickyHeader')}
                            testingName={'selectVehicleStickyHeader'} onClick={addCurrentVehicleToGarage}>
                        <Typography size={0.875} font={'bold'}>{`Add to Garage`}</Typography>
                    </Button>
                    : false)}
        </div>
    )
}


const StyledChipDivider = styled.div`
    border-right-color: ${(props) => props.dividerColor};
`;
const StyledChip = styled(TextLink)`
    background-color: ${(props) => `${props.backgroundColor}`};
    border-color: ${(props) => `${props.borderColor}`};
`;
export default StickyHeaderContent