const parseVehicleFromSelectedFacet = (selectedFacets) => {
  if (!selectedFacets) {
    return null;
  }
  const matchingFacetOption = selectedFacets.filter(
    (selectedFacet) =>
      selectedFacet.displayText === "Race Type" ||
      selectedFacet.displayText === "Fitment"
  );
  if (matchingFacetOption.length === 0) return null;
  return matchingFacetOption[0].searchResponseFacetOptions[0].displayValue;
};

const useVehicleSidebar = (
  selectedFacets
) => {
  const vehicleFacet = parseVehicleFromSelectedFacet(selectedFacets);

  return {
    label: vehicleFacet ? "Shopping for your:" : "Find the right parts for your vehicle",
    isVehicleSelected: vehicleFacet ? true : false,
    vehicleName: vehicleFacet
  };
};

export default useVehicleSidebar;
