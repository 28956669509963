import React, {useContext, useEffect, useState} from "react";

import TextLink from "Clutch/Atoms/TextLink";
import CoveringSpinner from 'Clutch/Atoms/CoveringSpinner'
import { useVehicleStore } from "Clutch/Stores/VehicleStore";

import { useUserStore } from "Clutch/Stores/UserStore/UserStore";
import { AddUserVehicle } from 'Clutch/Contexts/VehicleContext/CoreVehicleRequests'
import { showAddToGarage, getCurrentFitmentDetails } from '/Utilities/garageUtilities'
import Svg from 'Clutch/Atoms/Svg'
import useProductResultPage from "../../../hooks/useProductResultPage";
import styles from "./garageControls.module.scss";
import {NotificationContext} from 'Clutch/Contexts/NotificationContext/NotificationContext'
import useVehicleSidebar from "../../../hooks/useVehicleSidebar";
import addCurrentVehicle from "../../../Utilities/addCurrentVehicle";
import {createFitmentAddedSegmentEvent} from 'Clutch/Utilities/Instrumentation/Fitment/fitmentSegmentEvents'

const GarageControls = () => {

    const UpdateCustomerVehicles = useVehicleStore(x => x.UpdateCustomerVehicles)
    const customerVehicles = useVehicleStore(x => x.context.customerVehicles)


    const isBot = useUserStore((x) => x.context.isBot);
    const getGuid = useUserStore((x) => x.context.getGuid);
    const [addingToGarage, setAddingToGarage] = useState(false);
    const [shouldShowAdd, setShouldShowAdd] = useState(false);
    const selectedFacetOptions = useProductResultPage(x => x.selectedFacetOptions)

    const {PushNotification} = useContext(NotificationContext)
    const {vehicleName} = useVehicleSidebar(selectedFacetOptions);
    const selectedFacetData = useVehicleStore(x => x.context.selectedFacetData)

    useEffect(() => {
        setShouldShowAdd(showAddToGarage(selectedFacetOptions, customerVehicles));
    }, [selectedFacetOptions, addingToGarage, customerVehicles])


    const addVehicleToGarage = async () => {

        addCurrentVehicle(selectedFacetOptions, isBot, getGuid, selectedFacetData, UpdateCustomerVehicles, AddUserVehicle, setAddingToGarage,
            () => {

                PushNotification(`${vehicleName} added to your garage!`, "positive", 5000, null, {
                    intent: "postitive",
                    icon: "check"
                });
            }, () => PushNotification(`Something went wrong, please try that again`, 'negative'))
    }

    if (!shouldShowAdd) {
        return false;
    }

    const fitmentFacet = selectedFacetOptions?.find(x => x.displayText == "Fitment");

    let segmentAddEvent = {}
    if (fitmentFacet?.fitmentDetails) {
        segmentAddEvent = createFitmentAddedSegmentEvent(
            fitmentFacet.fitmentDetails.makeId,
            fitmentFacet.fitmentDetails.make,
            fitmentFacet.fitmentDetails.modelId,
            fitmentFacet.fitmentDetails.model,
            fitmentFacet.fitmentDetails.year,
            fitmentFacet.fitmentDetails.raceTypeId,
            fitmentFacet.fitmentDetails.raceType
        )
    }

    return (<CoveringSpinner isLoading={addingToGarage}>
        <TextLink data-testid="addVehicleToGarage" className={styles.vehicleName} intent={"action"} size={0.875}
                  onClick={addVehicleToGarage} segmentEvent={segmentAddEvent}>
            <Svg icon={"add"} className={styles.svg} size={0.875}/>
            Add To My Garage
        </TextLink>
    </CoveringSpinner>)
}

export default GarageControls;