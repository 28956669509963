import { useState } from 'react'
import Area from 'Clutch/Molecules/Area'
import Typography from 'Clutch/Atoms/Typography'
import Brands from 'Clutch/Constants/Brand'
import Intents from 'Clutch/Constants/Intent'
import Popover from 'Clutch/Components/Popover'
import styles from './productMatchScores.module.scss'
import Card from 'Clutch/Atoms/Card'

const ProductMatchScores = ({ scores, matchedFields }) => {

    if (!scores) {
        return false
    }

    const [openScore, setOpenScore] = useState(null)
    try {
        return (
            <Card noMargin noPadding className={styles.card}>
                <div className={styles.scoresWrapper}>
                    {
                        scores.map((score, index) => {
                            let brand
                            let intent
                            if (index < Intents.length - 1) {
                                intent = Intents[index]
                            }
                            else if (index < Intents.length + Brands.length - 1) {
                                brand = Brands[index]

                            }
                            else (
                                brand = Brands[0]
                            )

                            return (
                                <Popover
                                    content={
                                        <Typography size={1}>{score.field}</Typography>
                                    }
                                    isOpen={openScore === index}
                                    onToggleMenu={(x) => setOpenScore(x == true ? index : null)}
                                >
                                    <Area className={styles.score} brand={brand} intent={intent}>
                                        <Typography size={0.75}>{score.value}</Typography>
                                    </Area>
                                </Popover>
                            )
                        })
                    }
                    {matchedFields.length ?
                        <Popover
                            content={
                                <div>
                                    {matchedFields.map((field) => {
                                        return <Typography>{field}</Typography>
                                    })}
                                </div>
                            }
                            isOpen={openScore === scores.count}
                            onToggleMenu={(x) => setOpenScore(x == true ? scores.count : null)}
                        >
                            <Area className={styles.matchedFields} brand={'primary'}>
                                <Typography size={0.75}>Matched</Typography>
                            </Area>
                        </Popover> :
                        false
                    }
                </div>
            </Card>
        )
    }
    catch (err) {
        console.error(err)
        return false
    }
}

export default ProductMatchScores