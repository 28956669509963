
export const getCurrentFitmentDetails = (selectedFacets) => {
    return selectedFacets?.filter(x => x.fitmentDetails)[0]?.fitmentDetails
}


export const showAddToGarage = (selectedFacets, customerVehicles) => {
    if(!selectedFacets || !selectedFacets.length){
        return false;
    }
    const currentFitmentDetails = getCurrentFitmentDetails(selectedFacets)
    if (!currentFitmentDetails) {
        return false;
    }
    if (currentFitmentDetails.raceType) {
        return !customerVehicles?.some(x => x.raceTypeId == currentFitmentDetails.raceTypeId)
    }
    if (currentFitmentDetails.make && currentFitmentDetails.model && currentFitmentDetails.year) {
        return !customerVehicles?.some(x =>
            x.makeId == currentFitmentDetails.makeId &&
            x.modelId == currentFitmentDetails.modelId &&
            x.year == currentFitmentDetails.year
        )
    }
    return false;
}


